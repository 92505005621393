import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardServiceAcesso } from 'src/app/shared/services/auth.service';
import { AcessosComponent } from './acessos.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.ACESSOS,
        component: AcessosComponent,
        canActivate: [AuthGuardServiceAcesso]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuardServiceAcesso]
})
export class AcessosRoutingModule { }
