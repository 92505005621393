import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { ITeste, TestesAdmService } from 'src/app/shared/api/testes/testes.service';

@Component({
    selector: 'capa',
    templateUrl: './capa.component.html',
    styleUrls: ['./capa.component.css', '../play.component.scss']
})
export class CapaComponent implements OnInit {
    @Input() idTeste!: string;
    @Output() onContinuarClick = new EventEmitter();

    public teste?: ITeste;
    public showLoaderMidia = false;
    public midiacapa: IMidia[] = [];
    private subscribeTesteService!: Subscription;
    private _subscriberGetModiasRodada!: Subscription;
    

    constructor(
        private testeService: TestesAdmService,
        private midiaService: MidiasService
    ) {}

    ngOnInit(): void {
        this.getDadosTeste();
        this.getMidiaCapa(this.idTeste);
    }

    ngOnDestroy(): void {
        this.subscribeTesteService?.unsubscribe();
        this._subscriberGetModiasRodada?.unsubscribe();
    }

    private getDadosTeste() {
        this.subscribeTesteService = this.testeService
            .detalharTestePerfilPessoal(this.idTeste)
            .subscribe({
                next: (response) => {
                    this.teste = response;
                    this.teste.descricao = this.teste.descricao.replace(/\n/g, '<br>')
                }
            });
    }

    private getMidiaCapa(idLicenca: string) {
        this.showLoaderMidia = true;
        this._subscriberGetModiasRodada = this.midiaService.getMidiaCapa(idLicenca).subscribe({
            next: (response) => {
                this.showLoaderMidia = false;
                this.midiacapa = response.resultados;
            }
        });
    }

    onClickContinuar() {
        this.onContinuarClick.emit();
    }
}
