<mat-card class="card shadow content">


    <div class="w-100 d-flex justify-content-center mb-3">
        <img src="https://mindtest.com.br/static/iconw.png" width="50%">
    </div>


    <mat-card-title class="title">{{title}}</mat-card-title>
    <mat-card-subtitle class="description">{{description}}</mat-card-subtitle>


    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>
