import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { ITeste } from 'src/app/shared/api/testes/testes.service';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';
import { IMAGE_FORMATS, VIDEO_FORMATS } from 'src/app/shared/components/card-midia/image-video-formats.resource';
import { unformatedStringDateToPortugueseDateString } from 'src/app/shared/functions/data-transform';

@Component({
    selector: 'card-teste',
    templateUrl: './card-teste.component.html',
    styleUrls: ['./card-teste.component.scss'],
    animations: [
        trigger('cardAnimation', [
            state('collapsed', style({
                height: '0',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '0',
                overflow: 'hidden'
            })),
            state('expanded', style({
                height: '*',
                paddingTop: '0',
                paddingBottom: '0',
                opacity: '1',
                overflow: 'visible'
            })),
            transition('collapsed => expanded', animate('300ms 400ms ease-out')), // 400ms de atraso
            transition('collapsed <=> expanded', animate('300ms ease-out'))
        ]),
    ]
})
export class CardTesteComponent implements OnInit {
    @Input() teste!: ITeste;

    @Output() onIniciarClick = new EventEmitter();

    cardOpened = false;
    imageLoaded = false;
    midiaTeste?: IMidia;


    constantsWidthHeightMidia = ConstantsWidthHeightMidia;
    imageFormats = IMAGE_FORMATS;
    videoFormats = VIDEO_FORMATS;


    constructor(private midiasService: MidiasService) {}

    ngOnInit(): void {
        this.getCapaTeste();
    }

    converterData(data: string) {
        return unformatedStringDateToPortugueseDateString(data);
    }

    toggleCard(value: boolean) {
        this.cardOpened = value;
    }

    loadImage(){
        this.imageLoaded = true;
    }

    getCapaTeste(){
        const sub = this.midiasService.getMidiaCapa(this.teste.uuid).subscribe({
            next: (response) => {
                sub.unsubscribe();
                this.midiaTeste = response.resultados[0];
            }
        })
    }

    iniciar(){
        this.onIniciarClick.emit(this.teste);
    }
}
