import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MidiasComponent } from './midias.component';
import { CardMidiaModule } from "../../shared/components/card-midia/card-midia.module";
import { AdmMidiasService } from 'src/app/shared/api/testes/adm-midias.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
    declarations: [
        MidiasComponent
    ],
    exports: [
        MidiasComponent
    ],
    providers: [
        AdmMidiasService
    ],
    imports: [
        CommonModule,
        CardMidiaModule,
        MatIconModule,
        MatTooltipModule
    ]
})
export class MidiasModule { }
