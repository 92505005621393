<div class="w-100">
    <h4>
        Sugestões de tamanhos (px):

        <mat-icon
            matTooltip="Largura x Altura: 
            {{constantsWidthHeightMidia.lg.width}} x {{constantsWidthHeightMidia.lg.height}}
            {{constantsWidthHeightMidia.md.width}} x {{constantsWidthHeightMidia.md.height}}
            {{constantsWidthHeightMidia.sm.width}} x {{constantsWidthHeightMidia.sm.height}}"
            matTooltipClass="custom-tooltip"
            >help</mat-icon
        >
    </h4>
</div>
<div class="w-100 d-flex">
    <div *ngFor="let midia of midias" class="container-midia">
        <card-midia
            [file]="midia"
            [width]="300"
            [height]="342"
            [content_type]="content_type"
            [obj_referencia]="obj_referencia"
            (changeMidia)="onChangeMidia()"
        ></card-midia>
    </div>

    <!-- CARD ADICIONAL PARA ADICIONAR NOVA MIDIA -->
    <div class="container-midia">
        <card-midia
            [content_type]="content_type"
            [width]="300"
            [height]="342"
            [obj_referencia]="obj_referencia"
            (changeMidia)="onChangeMidia()"
        ></card-midia>
    </div>
</div>
