export const formatarNumeroTelefone = (numero: string) => {
    // Remove tudo que não for dígito
    const numeroLimpo = numero.replace(/[^0-9]/g, '');

    // Aplica a formatação com regex
    const numeroFormatado = numeroLimpo.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

    return numeroFormatado;
};

export const formatarCPF = (cpf: string) => {
    // Remove tudo que não for dígito
    let cpfLimpo = cpf.replace(/\D/g, '');

    // Aplica a formatação com regex
    let cpfFormatado = cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return cpfFormatado;
};

export const formatarCNPJ = (cnpj: string) => {
    // Remove tudo que não for dígito
    let cnpjLimpo = cnpj.replace(/\D/g, '');

    // Aplica a formatação com regex
    let cnpjFormatado = cnpjLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpjFormatado;
};

export const truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};
