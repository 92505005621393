import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comparacao-relatorio',
  templateUrl: './comparacao-relatorio.component.html',
  styleUrls: ['./comparacao-relatorio.component.css']
})
export class ComparacaoRelatorioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
