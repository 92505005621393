import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILicenca, LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { IMaterial } from 'src/app/shared/api/testes/adm-materiais.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { PessoalMateriaisService } from 'src/app/shared/api/testes/pessoal-materiais.service';
import { ConstantsWidthHeightMidia } from 'src/app/shared/components/card-midia/card-midia-constants';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-grupo-testes',
  templateUrl: './grupo-testes.component.html',
  styleUrls: ['./grupo-testes.component.css']
})
export class GrupoTestesComponent implements OnInit {

    loading = false;
    grupos: {idGrupo: string, licencasMateriais: {licenca: ILicenca, materiais: IMaterial[]}[]}[] = [];
    actionsToolbar: ActionToolbar[] = [];
    titulo = '';

    idTestePrincipal!: string;
    constantsWidthHeightMidia = ConstantsWidthHeightMidia;

    constructor(
        public licencasService: LicencasService,
        private dialog: DialogService,
        private router: Router,
        private jogadaService: JogadaService,
        private route: ActivatedRoute,
        private materiaisService: PessoalMateriaisService
    ){}

    ngOnInit(): void {
        this.idTestePrincipal = this.route.snapshot.paramMap.get('id')!;

        this.listarTestes();
    }

    private listarTestes(params: any = { pagina: 1, tamanho: 999 }) {

        this.loading = true;
        params['teste'] = this.idTestePrincipal;
        
        this.licencasService.get(params).subscribe({
            next: (response) => {
                this.loading = false;
                const licencas = response.resultados;
                this.obterNomeTestePrincipal(licencas);
                this.listarMateriaislicencas(licencas);
            }
        });
    }

    private obterNomeTestePrincipal(licencas: ILicenca[]){
        const licenca = licencas.find(licenca => licenca.teste.uuid == this.idTestePrincipal);
        this.titulo = licenca?.teste.nome || '';
    }

    private separarLicencasEmGrupos(licencas: ILicenca[], materiais: IMaterial[]){
        this.grupos = [];

        licencas.forEach(licenca => {
            let grupo = this.grupos.find(grupo => grupo.idGrupo == licenca.grupo);

            if(!grupo) {
                grupo = {
                    idGrupo: licenca.grupo,
                    licencasMateriais: []
                }

                this.grupos.push(grupo);
            }

            let materiaisTeste = materiais.filter(material => material.teste == licenca.teste.uuid);


            grupo.licencasMateriais.push({licenca: licenca, materiais: materiaisTeste});
        })
    }

    private listarMateriaislicencas(licencas: ILicenca[]){
                this.materiaisService.get().subscribe({
            next: (response) => {
                this.separarLicencasEmGrupos(licencas, response.resultados);
            }
        })
    }

    iniciarJogadaClick = (licenca: ILicenca) => {
        if(licenca.data_inicio){
            this.iniciarJogada(licenca);
            return;
        }

        this.dialog
            .confirm('Confirmar', 'Deseja realmente iniciar o jogo agora?')
            .subscribe((confirmado) => {
                if (confirmado) {
                    this.iniciarJogada(licenca);
                }
            });
    };

    visualizarRelatorio = (licenca: any) => {
        const url = ConstantsRotasDoSistema.RELATORIOS + '/' + licenca.uuid;
        this.router.navigate([url]);
    };

    iniciarJogada(licenca: ILicenca){
        this.jogadaService.getRodadaAtual(licenca.uuid).subscribe({
            next: (response) => {
                this.goToJogada(licenca.uuid);
            },
            error: () => {
                this.jogadaService.iniciarJogada(licenca.uuid).subscribe({
                    next: (response) => {
                        this.goToJogada(licenca.uuid);
                    }
                });
            }
        });
    }

    goToJogada(id: string) {
        this.router.navigate([ConstantsRotasDoSistema.DEFAULT_PATH + ConstantsRotasDoSistema.PLAY + `/${id}`]);
    }
}
