import { Injectable } from '@angular/core';
import { IResponse } from '../response.model';
import { TemplateApiService } from '../template-api.service';
import { ITeste } from './testes.service';

export interface IVersaoTeste extends IResponse {
    data_criacao: string;
    ativo: boolean;
    teste: ITeste;
}


@Injectable()
export class VersoesTestesAdmService extends TemplateApiService<IVersaoTeste> {
    public override setBaseUrl(idTeste: string) {
        this.baseUrl = `admin/testes/${idTeste}/versoes`;
    }

    public gerarNovaVersaoTeste(idTeste: string) {
        return this.apiService.post(this.getBaseUrl(), {});
    }

    public restaurarVersao(idVersao: string) {
        return this.apiService.patch(this.getBaseUrl() + `/${idVersao}`, {ativo: true});
    }
}
