import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";
import { Injectable } from "@angular/core";


export interface ILicenca extends IResponse {
    acesso_pessoal: string,
    ativo: boolean,
    data_criacao: string,
    data_inicio: string,
    data_fim: string,
    disponivel_ate: string,
    disponivel_de: string,
    dono: string,
    progresso: number,
    liberado: boolean,
    equipe: {uuid: string, nome: string},
    grupo: string,
    teste: {
        uuid: string, 
        nome: string, 
        /** url da capa */
        capa: string
    },
}


@Injectable()
export class LicencasService extends TemplateApiService<ILicenca> {

    public override setBaseUrl(params: {}) {
        this.baseUrl = `licencas`;
    }
}
