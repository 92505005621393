import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { TermoDeUsoComponent } from './termo-de-uso.component';
import { FormTermoComponent } from './form-termo/form-termo.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.TERMOS_DE_USO,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: TermoDeUsoComponent,
            },
            {
                path: 'novo',
                component: FormTermoComponent,
            },
            {
                path: ':id',
                component: FormTermoComponent,
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TermoDeUsoRoutingModule {}
