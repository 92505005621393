import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PessoalLicencasComponent } from './pessoal-licencas.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { GrupoTestesComponent } from './grupo-testes/grupo-testes.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.LICENCAS,
        component: PessoalLicencasComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: ConstantsRotasDoSistema.GRUPOS_LICENCAS + '/:id',
        component: GrupoTestesComponent,
        canActivate: [AuthGuardService],
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PessoalLicencasRoutingModule { }
