import { ConstantsRotasDoSistema } from "./shared/constants/constants-rotas-do-sistema";

export enum ETipoAcesso {
  ADMINISTRADOR = 'administrador',
  CLIENTE = 'cliente',
  PESSOAL = 'pessoal'
}

export interface NavigationMenu {
    text: string,
    icon?: string,
    path?: string,
    items?: NavigationMenu[],
    permissoes?: ETipoAcesso[]
}


export const navigation: NavigationMenu[] = [
    {
        text: 'Clientes',
        icon: 'group',
        path: ConstantsRotasDoSistema.CLIENTES,
        permissoes: [ETipoAcesso.ADMINISTRADOR],
    },
    {
        text: 'Cursos',
        icon: 'quiz',
        path: ConstantsRotasDoSistema.TESTES,
        permissoes: [ETipoAcesso.ADMINISTRADOR],
    },
    {
        text: 'Licenças',
        icon: 'workspace_premium',
        path: ConstantsRotasDoSistema.ADM_LICENCAS,
        permissoes: [ETipoAcesso.ADMINISTRADOR],
    },
    {
        text: 'Termos de uso',
        icon: 'edit_document',
        path: ConstantsRotasDoSistema.TERMOS_DE_USO,
        permissoes: [ETipoAcesso.ADMINISTRADOR],
    },
    {
        text: 'Meus Cursos',
        icon: 'play_circle',
        path: ConstantsRotasDoSistema.LICENCAS,
        permissoes: [ETipoAcesso.PESSOAL],
    },
    {
        text: 'Acesso Pessoal',
        icon: 'group',
        path: ConstantsRotasDoSistema.ACESSO_PESSOAL,
        permissoes: [ETipoAcesso.CLIENTE],
    },
    {
        text: 'Skins',
        icon: 'format_paint',
        path: ConstantsRotasDoSistema.SKINS,
        permissoes: [ETipoAcesso.CLIENTE],
    },
    {
        text: 'Equipes',
        icon: 'groups',
        path: ConstantsRotasDoSistema.EQUIPES,
        permissoes: [ETipoAcesso.CLIENTE],
    },
];
