<div class="container-fluid mt-2 pb-5">

    <toolbar [actions]="actionsToolbar" [titulo]="titulo"></toolbar>

    <loader-page [show]="loading"></loader-page>

    <ng-container *ngFor="let grupo of grupos; let isLast = last">
        <div class="grupo mt-5">
            <ng-container *ngFor="let licencaMaterial of grupo.licencasMateriais">
                <div 
                    [ngStyle]="{
                        'min-width': constantsWidthHeightMidia.sm.width + 'px',
                        'max-width': constantsWidthHeightMidia.sm.width + 'px'
                    }"
                >
                    <card-licenca
                        (onIniciarClick)="iniciarJogadaClick($event)"
                        (onRelatorioClick)="visualizarRelatorio($event)"
                        [licenca]="licencaMaterial.licenca"
                    ></card-licenca>
                </div>
                <div *ngFor="let material of licencaMaterial.materiais"
                    [ngStyle]="{
                        'min-width': constantsWidthHeightMidia.sm.width + 'px',
                        'max-width': constantsWidthHeightMidia.sm.width + 'px'
                    }"
                >
                    <card-material
                        [material]="material"
                    ></card-material>
                </div>
            </ng-container>

            <br>
        </div>
    </ng-container>
</div>
