<div class="container-fluid mt-2">

    <!-- <card title="Filtros">
        <formulario-dinamico
            [colunas]="filtroPessoalLicencasResource.colunas"
            [(entidade)]="filtro"
            (entidadeChange)="changeFiltro($event)"
        ></formulario-dinamico>
    </card> -->

    <loader-page [show]="loading"></loader-page>

    <div class="row">
        <ng-container *ngFor="let teste of testes">
            <div class="col-xxl-3 col-lg-4 col-md-4 col-sm-6 mt-4">
                <card-teste (onIniciarClick)="irParaGrupo($event)" [teste]="teste"></card-teste>
            </div>
        </ng-container>
    </div>

    <div [ngClass]="{'d-none': paginacao.total < 13}">
        <mat-paginator
            class="mt-4"
            [pageSizeOptions]="[12]"
            [length]="paginacao.total || 0"
            showFirstLastButtons
            (page)="alterarPagina($event)"
        ></mat-paginator>
    </div>
</div>
