<container-padrao>
    <toolbar [actions]="actionsToolbar" titulo="Associar licença"></toolbar>

    <card>
        <mat-tab-group
            #grupoTab
            (selectedTabChange)="grupoTabSelecionada = $event.index"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            class="vinculo-licencas"
        >
            <mat-tab label="Associar ao cliente">
                <div *ngIf="grupoTab.selectedIndex == grupoTabAssociarCliente">
                    <ng-container
                        *ngTemplateOutlet="
                            formularioDinamico;
                            context: {
                                showInputQuantidade: true,
                                showSelectAcessosPessoais: false,
                                showSelectLicencas: false,
                                showSelectTestes: true,
                                showSelectEquipes: true,
                                showDisponivelDe: false,
                                showDisponivelAte: false
                            }
                        "
                    ></ng-container>
                </div>
            </mat-tab>
            <mat-tab label="Associar para acesso pessoal">
                <div *ngIf="grupoTab.selectedIndex == grupoTabAssociarAcessoPessoal">
                    <ng-container
                        *ngTemplateOutlet="
                            formularioDinamico;
                            context: {
                                showInputQuantidade: false,
                                showSelectAcessosPessoais: true,
                                showSelectLicencas: true,
                                showSelectTestes: false,
                                showSelectEquipes: true,
                                showDisponivelDe: true,
                                showDisponivelAte: true
                            }
                        "
                    ></ng-container>
                </div>
            </mat-tab>
        </mat-tab-group>
    </card>
</container-padrao>

<ng-template
    #formularioDinamico
    let-showInputQuantidade="showInputQuantidade"
    let-showSelectAcessosPessoais="showSelectAcessosPessoais"
    let-showSelectLicencas="showSelectLicencas"
    let-showSelectTestes="showSelectTestes"
    let-showSelectEquipes="showSelectEquipes"
    let-showDisponivelDe="showDisponivelDe"
    let-showDisponivelAte="showDisponivelAte"
>
    <form [formGroup]="formulario" class="mt-4">
        <div class="row">
            <div class="col-lg-6">
                <div>
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="colunaAcessosClientes"
                        [(value)]="cliente"
                        (valueChange)="changeCliente($event)"
                    ></lookup>
                </div>

                <div *ngIf="showSelectTestes">
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="colunaTestesDisponiveis"
                        [service]="TestesAdmService"
                        [(value)]="teste"
                    ></lookup>
                </div>

                <div *ngIf="showSelectLicencas && cliente">
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="colunaLicencasDisponiveis"
                        [service]="licencasDisponiveisAdmService"
                        [(value)]="licenca"
                        (valueChange)="changeLicencasDisponiveis($event)"
                    ></lookup>
                </div>

                <div *ngIf="showSelectEquipes && cliente">
                    <lookup
                        [parentFormGroup]="formulario"
                        [coluna]="colunaEquipesCliente"
                        [service]="admEquipeService"
                        [(value)]="equipe"
                        (valueChange)="changeSelectEquipe($event)"
                    ></lookup>
                </div>

                <div *ngIf="showInputQuantidade">
                    <mat-form-field appearance="fill">
                        <mat-label> Digite a quantidade de licenças a serem liberadas </mat-label>
                        <input
                            [formControlName]="colunaQuantidadeLicencas.field"
                            [(ngModel)]="quantidadeLicencas"
                            [min]="0"
                            [max]="quantidadeMaximaLicencas"
                            [name]="colunaQuantidadeLicencas.field"
                            width="100%"
                            type="number"
                            matInput
                        />
                    </mat-form-field>
                </div>

                <div *ngIf="showDisponivelDe && cliente">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ colunaDisponivelDe.label }}</mat-label>
                        <input
                            matInput
                            [formControlName]="colunaDisponivelDe.field"
                            [name]="colunaDisponivelDe.field"
                            [(ngModel)]="disponivelDe"
                            [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div *ngIf="showDisponivelAte && cliente">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ colunaDisponivelAte.label }}</mat-label>
                        <input
                            matInput
                            [formControlName]="colunaDisponivelAte.field"
                            [name]="colunaDisponivelAte.field"
                            [(ngModel)]="disponivelAte"
                            [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-6">
                <div *ngIf="cliente && showSelectAcessosPessoais" class="d-grid">
                    <secondary-button
                        icon="search"
                        (click)="openModalSelecionarAcessos()"
                        text="Selecionar acessos pessoais já existentes"
                    ></secondary-button>

                    <primary-button
                        (click)="openModalCadastrarAcesso()"
                        [icon]="'playlist_add'"
                        class="mt-2"
                        text="Adicionar novo acesso pessoal"
                    ></primary-button>

                    <div class="mt-3">
                        <tabela-estatica
                            *ngIf="acessosPessoaisSelecionados.length > 0"
                            class="mt-3"
                            [entidades]="acessosPessoaisSelecionados"
                            [colunas]="acessoPessoalFormResource"
                            minWidth="30em"
                        ></tabela-estatica>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<modal
    #modalSelecionarAcessos
    [data]="modalSelecionarAcessosData"
    [onSave]="onSaveModalSelecionarAcessos"
>
    <tabela-listagem
        *ngIf="cliente"
        #listaAcessosPessoais
        [colunas]="acessoPessoalFormResource"
        [service]="acessosPessoaisClienteService"
        [valueExpr]="'uuid'"
        [ativarSelecao]="true"
        [selectionMode]="'multiple'"
    ></tabela-listagem>
</modal>

<modal
    #modalAdicionarAcessoPessoal
    [data]="modalAdicionarAcessoPessoalData"
    [onSave]="onSaveModalAdicionarAcessoPessoal"
>
    <formulario-dinamico
        #formularioAdicionarAcessoPessoal
        [colunas]="acessoPessoalFormResource"
        [(entidade)]="acessoPessoalEntidadeForm"
    ></formulario-dinamico>
</modal>
