<div class="col-sm-6 esquerda p-0">
    <slider-midia *ngIf="!showLoader" [midias]="midias" class="area-midias"></slider-midia>

    <div *ngIf="showLoader" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>

<div class="col-sm-6 direita ps-4 pe-3 pb-2" *ngIf="entity">
    <div class="d-grid align-items-center">
        <div class="d-flex">
            <ng-container *ngIf="!disableBefore">
                <button (click)="backFunction()" mat-mini-fab color="basic" class="me-2 ms-2 mt-2">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </ng-container>
            <h1 class="text-center mt-4 titulo w-100">{{entity.nome}}</h1>
        </div>
    
        <div class="p-4 mt-4 descricao">
            <div [innerHTML]="entity.descricao"></div>
        </div>
    
        <btn-continuar (click)="onClickContinuar()" class="mt-4"></btn-continuar>
    </div>
    
    <div *ngIf="!entity" class="d-grid align-items-center justify-content-center">
        <loader-page [show]="true"></loader-page>
    </div>
</div>