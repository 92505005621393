import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PessoalLicencasRoutingModule } from './pessoal-licencas-routing.module';
import { PessoalLicencasComponent } from './pessoal-licencas.component';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';
import { LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CardLicencaComponent } from './card-licenca/card-licenca.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoaderPageModule } from '../../shared/components/loader-page/loader-page.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormularioDinamicoModule } from '../../shared/components/formulario-dinamico/formulario-dinamico.module';
import { CardModule } from '../../shared/components/card/card.module';
import { CardTesteComponent } from './card-teste/card-teste.component';
import { GrupoTestesComponent } from './grupo-testes/grupo-testes.component';
import { ToolbarModule } from '../../shared/components/toolbar/toolbar.module';
import { CardMaterialComponent } from './card-material/card-material.component';

@NgModule({
    declarations: [
        PessoalLicencasComponent,
        CardLicencaComponent,
        CardTesteComponent,
        GrupoTestesComponent,
        CardMaterialComponent
    ],
    imports: [
        CommonModule,
        PessoalLicencasRoutingModule,
        TabelaListagemModule,
        ContainerPadraoModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        LoaderPageModule,
        MatProgressBarModule,
        FormularioDinamicoModule,
        CardModule,
        ToolbarModule
    ],
    providers: [LicencasService, DialogService, JogadaService]
})
export class PessoalLicencasModule {}
