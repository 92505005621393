import { formatarCNPJ } from "src/app/shared/functions/format-functions";
import {ColumnDataType, Resource} from "../../shared/model/resource";
import { displayColorHexadeciamlToHTMLCircle } from "src/app/shared/functions/color-functions";

export const clienteResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'Cliente',
        plural: 'Clientes'
    },
    fieldPk: 'uuid',
    route: {
        url: 'clientes',
    },
    colunas: [
        {
            field: 'ativo',
            label: 'Ativo',
            required: false,
            readOnly: true,
            type: ColumnDataType.Checkbox,
            textAlignCellTable: 'center',
            calculateDisplayValue: (ativo: boolean) => {
                if (ativo) return displayColorHexadeciamlToHTMLCircle('#28a745', 15, 15);
                return displayColorHexadeciamlToHTMLCircle('#dc3545', 15, 15);
            },
            calculateDisplayTooltip: (value) => (value ? 'Ativo' : 'Inativo')
        },
        {
            field: 'username',
            label: 'E-mail',
            validation: [{type: 'email'}],
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'senha',
            label: 'Senha',
            type: ColumnDataType.Password,
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'nome',
            label: 'Nome',
            required: true,
            unAvailableOnList: true,
            unAvailableOnEdit: true,
        },
        {
            field: 'razao_social',
            label: 'Razão Social',
            required: true,
        },
        {
            field: 'nome_fantasia',
            label: 'Nome Fantasia',
            required: true
        },
        {
            field: 'cnpj',
            label: 'CNPJ',
            required: true,
            calculateDisplayValue: formatarCNPJ
        },
    ],
    actions: [],
    availableReferences: []
}
