import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientesComponent } from './clientes.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { ClienteFormComponent } from './cliente-form/cliente-form.component';
import { FormsEquipesClienteComponent } from './forms-equipes-cliente/forms-equipes-cliente.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.CLIENTES,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: ClientesComponent
            },
            {
                path: 'novo',
                component: ClienteFormComponent
            },
            {
                path: ':id',

                children: [
                    {
                        path: '',
                        component: ClienteFormComponent
                    },
                    {
                        path: ConstantsRotasDoSistema.EQUIPES,
                        children: [
                            {
                                path: 'novo',
                                component: FormsEquipesClienteComponent
                            },
                            {
                                path: ':idEquipe',
                                component: FormsEquipesClienteComponent
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientesRoutingModule {}
