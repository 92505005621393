import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormularioTesteComponent } from './formulario-teste.component';
import { ToolbarModule } from '../../../../shared/components/toolbar/toolbar.module';
import { ContainerPadraoModule } from '../../../../shared/components/container-padrao/container-padrao.module';
import { CardModule } from '../../../../shared/components/card/card.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { LookupModule } from '../../../../shared/components/lookup/lookup.module';
import { TestesAdmService } from 'src/app/shared/api/testes/testes.service';
import { CardMidiaModule } from '../../../../shared/components/card-midia/card-midia.module';
import { CardMidiaFormularioModule } from '../../../../shared/components/card-midia-formulario/card-midia-formulario.module';
import { ModalModule } from '../../../../shared/components/modal/modal.module';
import { TabelaListagemModule } from '../../../../shared/components/tabela-listagem/tabela-listagem.module';

@NgModule({
    declarations: [FormularioTesteComponent],
    imports: [
        CommonModule,
        ToolbarModule,
        ContainerPadraoModule,
        CardModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        LookupModule,
        CardMidiaFormularioModule,
        ModalModule,
        TabelaListagemModule
    ],
    providers: [TestesAdmService],
    exports: [FormularioTesteComponent]
})
export class FormularioTesteModule {}
