import { CommonModule } from "@angular/common";
import { Component, NgModule, OnDestroy } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Router, RouterModule } from "@angular/router";
import { ConstantsRotasDoSistema } from "src/app/shared/constants/constants-rotas-do-sistema";
import { AuthService } from "src/app/shared/services/auth.service";
import { NotificationService } from "src/app/shared/services/notificationService";


@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.css']
})
export class LoginFormComponent implements OnDestroy {
    loading = false;
    formData: any = {};
    hide = true;
    private _subscribeApi:any = null;


    ngOnDestroy(): void {
        if(this._subscribeApi) this._subscribeApi.unsubscribe();
    }

    constructor(private authService: AuthService, private router: Router, private notify: NotificationService) {
    }

    async onSubmit(e: Event) {
        e.preventDefault();
        const {username, senha} = this.formData;
        this.loading = true;

        this._subscribeApi = this.authService.logIn(username, senha).subscribe({
            next: () => {
                this.loading = false;
                this.router.navigate([ConstantsRotasDoSistema.DEFAULT_PATH]);
            },
            error: (e: any) => {
                this.loading = false;
                this.notify.error(e.error.erro);
            },
        });
    }

    // onCreateAccountClick = () => {
    //     this.router.navigate(['/create-account']);
    // }
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    declarations: [LoginFormComponent],
    exports: [LoginFormComponent]
})
export class LoginFormModule {
}
