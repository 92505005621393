<div
    class="card-material"
    [ngStyle]="{
        'aspect-ratio': constantsWidthHeightMidia.lg.width / constantsWidthHeightMidia.lg.height
    }"
>
    <div class="card-title">{{ material.nome }}</div>

    <div class="card-content">
        {{ material.descricao }}
    </div>

    <div class="card-actions">
        <div class="row">
            <div>
                <button mat-mini-fab color="primary" (click)="iniciar()">
                    <mat-icon>play_arrow</mat-icon>
                </button>
                <span class="ms-3">Iniciar</span>
            </div>
        </div>
    </div>
</div>
