import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardServiceAceiteTermos } from 'src/app/shared/services/auth.service';
import { AceiteDeTermosComponent } from './aceite-de-termos.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.ACEITE_DE_TERMOS,
        canActivate: [AuthGuardServiceAceiteTermos],
        component: AceiteDeTermosComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AceiteDeTermosRoutingModule { }
