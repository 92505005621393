import { Component, OnInit, ViewChild } from '@angular/core';
import { EditComponentComponent } from 'src/app/shared/components/edit-component/edit-component.component';
import { ModulosComponent } from '../../modulos/modulos.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-teste-form',
    templateUrl: './teste-form.component.html',
    styleUrls: ['./teste-form.component.css']
})
export class TesteFormComponent implements OnInit {
    @ViewChild('formTeste') formTeste!: EditComponentComponent;
    @ViewChild('modulosComponent') modulosComponent!: ModulosComponent;

    id: any;

    constructor(
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    changeVersao(){
        this.modulosComponent.listarModulos();
    }
    
}
