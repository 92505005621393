import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SelectConfiguration } from '../../model/resource';
import { AcessosService, IAcesso } from '../../api/acessos.service';
import { NotificationService } from '../../services/notificationService';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() title!: string;
    @Input() showBtnMenu = true;

    @Output() menuToggle = new EventEmitter();

    configurationSelectPerfis!: SelectConfiguration;
    acessoSelecionado: any = null;

    constructor(
        private authService: AuthService,
        private acessosService: AcessosService,
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        this.configurationSelectPerfis = {
            displayExpr: 'tipo',
            valueExpr: 'uuid',
            service: this.acessosService,
            onChange: this.changeTipoAcesso,
            calculateDisplayValue: (acesso: IAcesso) => {
                if(acesso.tipo == 'cliente'){
                    return acesso.detalhe?.nome_fantasia + ' - ' + acesso.tipo;
                }
                if(acesso.tipo == 'pessoal'){
                    const username = acesso.usuarios ? acesso.usuarios[0].username : '';
                    return username + ' - ' + acesso.tipo;
                }
                return acesso.tipo;
            }
        };

        this.acessoSelecionado = {uuid: this.authService.getAcesso(), tipo: this.authService.getTipoAcesso()};
    }

    toggleMenuLateral() {
        this.menuToggle.emit();
    }

    logout() {
        this.authService.logOut();
    }

    private changeTipoAcesso = (acesso: IAcesso) => {
        this.acessosService.selecionarAcesso(acesso.uuid).subscribe({
            next: (response) => {
                this.authService.selecionarAcesso(acesso, response.token);
            },
            error: (e: any) => {
                this.notify.error(e.error.erro);
            }
        });
    }
}
