import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelatoriosComponent } from './relatorios.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { ResultadoComponent } from './resultado/resultado.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.RELATORIOS,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: RelatoriosComponent,
            },
            {
                path: ':id',
                component: ResultadoComponent
            }
        ]
    },
    {
        path: ConstantsRotasDoSistema.COMPARACAO_RELATORIOS,
        canActivate: [AuthGuardService],
        children: [
            {
                path: ':id'
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule { }
