import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TestesComponent } from "./testes.component";
import { AuthGuardService } from "src/app/shared/services/auth.service";
import { TesteFormComponent } from "./teste-form/teste-form.component";
import { ConstantsRotasDoSistema } from "src/app/shared/constants/constants-rotas-do-sistema";

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.TESTES,
        children: [
            {
                path: '',
                component: TestesComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'novo',
                component: TesteFormComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: ':id',
                component: TesteFormComponent,
                canActivate: [AuthGuardService],
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestesRoutingModule { }
