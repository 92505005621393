import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkinsComponent } from './skins.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { FormSkinComponent } from './form-skin/form-skin.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.SKINS,
        children: [
            {
                path: '',
                component: SkinsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'novo',
                component: FormSkinComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: ':id',
                component: FormSkinComponent,
                canActivate: [AuthGuardService],
            },
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SkinsRoutingModule { }
