import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutocadastroFormComponent } from './autocadastro-form.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.AUTOCADASTRO,
        children: [
            {
                path: ':id',
                component: AutocadastroFormComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AutocadastroFormRoutingModule {}
