import { Injectable } from "@angular/core";
import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";
import { ITeste } from "./testes.service";


@Injectable()
export class TestesService extends TemplateApiService<ITeste> {

    public override setBaseUrl(...props: any) {
        this.baseUrl = 'testes';
    }
}
