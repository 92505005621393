export const ConstantsWidthHeightMidia = {
    sm: {
        width: 300,
        height: 342
    },
    md: {
        width: 489,
        height: 558
    },
    lg: {
        width: 1024,
        height: 1168
    }
}