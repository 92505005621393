import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { perguntaResource } from './pergunta.resource';
import { ConfigurationColumn } from 'src/app/shared/model/resource';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { IActionsCardbotoes } from 'src/app/shared/components/card-botoes/card-botoes.component';
import { Observable, Subscription } from 'rxjs';
import { IPergunta, PerguntaService } from 'src/app/shared/api/testes/perguntas.service';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
    selector: 'perguntas',
    templateUrl: './perguntas.component.html',
    styleUrls: ['./perguntas.component.css']
})
export class PerguntasComponent implements OnInit, OnDestroy {
    @ViewChild('modal') modal!: ModalComponent;
    @ViewChild('formularioPergunta') formularioPergunta!: FormularioDinamicoComponent;

    @Input() idTeste!: string;
    @Input() idModulo!: string;
    @Input() idRodada!: string;


    perguntaResouce = perguntaResource;
    colunasFormulario: ConfigurationColumn[] = [];
    dataModal: IDialogData = { titulo: '' };
    showFormModal = false;

    actions: IActionsCardbotoes[] = [];
    entidadePergunta!: any;
    listaPerguntas: IPergunta[] = [];

    private _subscriberListagemPerguntas: Subscription | null = null;
    private _subscriberModal: Subscription | null = null;
    private _subscriberCreatePergunta: Subscription | null = null;

    readonly ADICIONAR_PERGUNTA = 'Adicionar ' + perguntaResource.nomeEntidade.singular;
    readonly EDITAR_PERGUNTA = 'Editar ' + perguntaResource.nomeEntidade.singular;
    readonly EXCLUIR_PERGUNTA = 'Excluir ' + perguntaResource.nomeEntidade.singular;

    constructor(
        private perguntaService: PerguntaService,
        private notify: NotificationService,
        private dialog: DialogService
    ) { }

    ngOnInit(): void {
        this.perguntaService.setBaseUrl(this.idTeste, this.idModulo, this.idRodada);

        this.actions.push({
            hint: this.EDITAR_PERGUNTA,
            icon: 'edit',
            text: this.EDITAR_PERGUNTA,
            type: 'primary',
            onClick: this.abrirModalEditarPergunta
        })

        this.actions.push({
            hint: this.EXCLUIR_PERGUNTA,
            icon: 'delete',
            text: this.EXCLUIR_PERGUNTA,
            type: 'warn',
            onClick: this.excluirPergunta
        })

        this.listarPerguntas();
    }

    ngOnDestroy(): void {
        if (this._subscriberListagemPerguntas) this._subscriberListagemPerguntas.unsubscribe();
        if (this._subscriberModal) this._subscriberModal.unsubscribe();
        if (this._subscriberCreatePergunta) this._subscriberCreatePergunta.unsubscribe();
    }

    listarPerguntas = () => {
        this._subscriberListagemPerguntas = this.perguntaService.get({tamanho: 999}).subscribe({
            next: response => {
                this.listaPerguntas = response.resultados;
            }
        })
    }

    excluirPergunta = (entity: IPergunta) => {
        this.dialog.confirm('Tem certeza que deseja excluir a pergunta selecionada?', entity.descricao).subscribe({
            next: (confirmed) => {
                if(confirmed){
                    this.perguntaService.delete(entity.uuid).subscribe({
                        next: (response) => {
                            this.listarPerguntas();
                        }
                    })
                }
            }
        })
    }


    showModalFormularioRodada() {
        this.showFormModal = true;
        this._subscriberModal = this.modal.open({ width: '40em' }).afterClosed().subscribe({
            next: () => {
                this.showFormModal = false;
            }
        });
    }

    abrirModalCriarPergunta() {
        this.entidadePergunta = {};
        this.dataModal.titulo = this.ADICIONAR_PERGUNTA;
        this.colunasFormulario = this.perguntaResouce.colunas.filter(c => !c.unAvailableOnCreate);
        this.showModalFormularioRodada();
    }

    abrirModalEditarPergunta = (entity: IPergunta) => {
        this.entidadePergunta = entity;
        this.dataModal.titulo = this.EDITAR_PERGUNTA;
        this.colunasFormulario = this.perguntaResouce.colunas.filter(c => c);
        this.showModalFormularioRodada();
    }


    onSavePergunta() {
        return new Observable(observer => {
            if (!this.formularioPergunta.isValid()) {
                observer.error();
                return;
            }

            const rodada = this.formularioPergunta.getDadosFormularioSalvar();

            let createOrSaveRequest = null;
            if (this.entidadePergunta?.uuid) {
                createOrSaveRequest = this.perguntaService.update(this.entidadePergunta.uuid, rodada);
            } else {
                createOrSaveRequest = this.perguntaService.create(rodada);
            }

            this._subscriberCreatePergunta = createOrSaveRequest.subscribe({
                next: () => {
                    this.listarPerguntas();
                    observer.next();
                },
                error: (e: any) => {
                    this.formularioPergunta.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error();
                }
            })
        })
    }
}
