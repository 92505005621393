<container-padrao>
    <toolbar [titulo]="resource.nomeEntidade.singular" [actions]="actions"></toolbar>
</container-padrao>

<container-padrao>   
    <card [title]="resource.nomeEntidade.singular">
        <form [formGroup]="formulario" *ngIf="formulario">
            <div class="d-flex" style="flex-wrap: wrap; gap: 10px">
                <!-- CAPA -->
                <div *ngIf="cardMidiaConfiguration && id">
                    <card-midia-formulario
                        [content_type]="cardMidiaConfiguration.content_type"
                        [obj_referencia]="cardMidiaConfiguration.obj_referencia"
                    ></card-midia-formulario>
                </div>

                <div style="min-width: 480px; flex: 1 1 auto">
                    <!-- NOME -->
                    <mat-form-field appearance="fill">
                        <mat-label>Nome</mat-label>
                        <input
                            type="text"
                            formControlName="nome"
                            matInput
                            [(ngModel)]="entidade.nome"
                            name="nome"
                        />

                        <mat-error *ngIf="formulario.get('nome')?.hasError('apiError')">
                            {{ formulario.get('nome')?.getError('apiError') }}
                        </mat-error>
                    </mat-form-field>

                    <!-- DESCRIÇÃO -->
                    <mat-form-field appearance="fill" class="form-textarea">
                        <mat-label>Descrição</mat-label>

                        <textarea
                            [rows]="13"
                            formControlName="descricao"
                            matInput
                            [(ngModel)]="entidade.descricao"
                            name="descricao"
                        ></textarea>

                        <mat-error *ngIf="formulario.get('descricao')?.hasError('apiError')">
                            {{ formulario.get('descricao')?.getError('apiError') }}
                        </mat-error>
                    </mat-form-field>

                    <!-- TESTE ANTERIOR -->
                    <lookup
                        *ngIf="testeAnteriorConfiguration"
                        [parentFormGroup]="formulario"
                        [coluna]="testeAnteriorConfiguration"
                        [service]="testesAdmService"
                        [entidade]="entidade"
                        [(value)]="entidade.antecessor"
                    ></lookup>
                </div>
            </div>
        </form>
    </card>
</container-padrao>

<!-- <modal #modalVersoesTeste [data]="dataModalVersoesTeste" [showBtnConfirmar]="false">
    <tabela-listagem
        #tabelaVersoesTestes
        [service]="versoesTesteService"
        [colunas]="colunasVersoesTestes.colunas"
        [actions]="actionsVersoesTestes"
    >
    </tabela-listagem>
</modal> -->
