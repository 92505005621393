<formulario-teste [id]="id"
    (onNovaVersaoGerada)="changeVersao()"
></formulario-teste>

<ng-container *ngIf="id">
    <container-padrao>
        <modulos-testes #modulosComponent></modulos-testes>
    </container-padrao>
</ng-container>

<ng-container *ngIf="id">
    <container-padrao>
        <materiais-curso [id]="id"></materiais-curso>
    </container-padrao>
</ng-container>

