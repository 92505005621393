import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import {
    ITermo,
    TermosDeUsoService
} from 'src/app/shared/api/termos-de-uso.service';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';
import { AuthService } from 'src/app/shared/services/auth.service';

interface ITermoListagem extends ITermo {
    aceito: boolean;
}

@Component({
    selector: 'app-aceite-de-termos',
    templateUrl: './aceite-de-termos.component.html',
    styleUrls: ['./aceite-de-termos.component.css']
})
export class AceiteDeTermosComponent implements OnInit {
    constructor(
        private termosDeUsoService: TermosDeUsoService,
        private authService: AuthService,
        private router: Router
    ) {}

    listaTermos: ITermoListagem[] = [];

    ngOnInit(): void {
        this.listarTermos();
    }

    listarTermos() {
        this.termosDeUsoService.get({ pagina: 1, tamanho: 999 }).subscribe({
            next: (response) => {
                if (response.resultados.length == 0) {
                    this.authService.removeShowAceiteTermos();
                    this.router.navigate([ConstantsRotasDoSistema.DEFAULT_PATH]);
                    return;
                }

                this.listaTermos = response.resultados.map((termo) => {
                    const termoLista: ITermoListagem = {
                        ...termo,
                        aceito: false
                    };
                    return termoLista;
                });
            }
        });
    }

    aceitarTermosSelecionados() {
        const requests = this.listaTermos
            .filter((termo) => termo.aceito)
            .map((termo) => this.termosDeUsoService.aceitarTermo(termo.uuid));

        forkJoin(requests).subscribe((results) => {
            this.listarTermos();
        });
    }
}
