export const ConstantsRotasDoSistema = {
    DEFAULT_PATH: '/',
    LOGIN: 'login',
    RESET_PASSWORD: 'reset-password',
    ALTERAR_SENHA: 'alterar-senha',
    ACEITE_DE_TERMOS: 'aceite-de-termos',
    ACESSO_PESSOAL: 'acesso-pessoal',
    ACESSOS: 'acessos',
    ADM_LICENCAS: 'adm/licencas',
    AUTOCADASTRO: 'autocadastro',
    CLIENTES: 'clientes',
    EQUIPES: 'equipes',
    LICENCAS: 'licencas',
    GRUPOS_LICENCAS: 'licencas/grupo',
    PLAY: 'play',
    RELATORIOS: 'relatorios',
    COMPARACAO_RELATORIOS: 'comparacao-relatorio',
    SKINS: 'skins',
    TERMOS_DE_USO: 'termos-de-uso',
    TESTES: 'testes'
}