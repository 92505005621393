import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdmLicencasComponent } from './adm-licencas.component';
import { AuthGuardService } from 'src/app/shared/services/auth.service';
import { AdmLicencasFormComponent } from './adm-licencas-form/adm-licencas-form.component';
import { ConstantsRotasDoSistema } from 'src/app/shared/constants/constants-rotas-do-sistema';

const routes: Routes = [
    {
        path: ConstantsRotasDoSistema.ADM_LICENCAS,
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                component: AdmLicencasComponent
            },
            {
                path: 'novo',
                component: AdmLicencasFormComponent
            }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdmLicencasRoutingModule { }
